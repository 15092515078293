"use strict";
//SwiperJS
import Swiper, { Navigation, Pagination } from "swiper";
import "../../node_modules/swiper/swiper-bundle.css";
//Imported Images
import closeImg from "../img/icons/close.svg";
import menuImg from "../img/icons/menu.svg";
//EmailJS
import emailjs from "@emailjs/browser";
//Sweet Alert
import Swal from "sweetalert2";
//Nav Bar
const header = document.querySelector("header");
const nestedElement = document.querySelector(".menu__item--show");
const list = document.querySelector(".menu__links");
const menu = document.querySelector(".menu__hamburguer");
const menuImage = document.querySelector(".menu__img");
const nav = document.querySelector(".menu");
const nestedMenu = document.querySelector(".menu__nesting");
//Team & Integrants
const teamBtnContainer = document.querySelector(".team__btnContainer");
const team = document.querySelector(".img__team__container");
const footerTeam = document.querySelector(".footer--team");
const integrantSwiper = document.querySelector(".swiper-team");
const footerIntegrants = document.querySelector(".footer--integrants");

//Projects & Services
const projectBtnContainer = document.querySelector(".projects__btnContainer");
const masonry = document.querySelector(".masonry");
const swiperProjects = document.querySelector(".swiper-projects");
const arrows = document.querySelectorAll(".arrow");
const upperArrows = document.querySelectorAll(".upper__arrow");

//-- Projects Info

//Contact Form
const form = document.querySelector(".form__container");
const inputs = document.querySelectorAll(".tab");
const steps = document.querySelectorAll(".form__number");
const stepsDes = document.querySelectorAll(".step__text");
const previousBtn = document.querySelector(".previous__btn");
const nextBtn = document.querySelector(".next__btn");
const submitBtn = document.querySelector(".submit__btn");
const errorMessage = document.querySelector(".input--error");
const loading = document.querySelector(".overlay");

const errorMessages = {
  required: "*Campo Obligatorio",
  email: "*Correo inválido, por favor inténtalo de nuevo",
  phone: "*Número inválido, por favor inténtalo de nuevo",
};

/** MENU JAVASCRIPT */

/**
 * Sticky Menu
 */

const { height: navHeight } = nav.getBoundingClientRect();

const stickyNav = function (entries) {
  const [entry] = entries;

  if (!entry.isIntersecting) {
    nav.classList.add("sticky");
    nestedMenu.classList.add("menu--nesting-sticky");
  } else {
    nav.classList.remove("sticky");
    nestedMenu.classList.remove("menu--nesting-sticky");
  }
};

const headerObserver = new IntersectionObserver(stickyNav, {
  root: null,
  threshold: 0,
  rootMargin: `-${navHeight}px`,
});

headerObserver.observe(header);

/**
 * Add click event to the nested menu (responsive)
 */
const addClick = () => {
  nestedElement.addEventListener("click", () => {
    let subMenu = nestedElement.lastElementChild;
    let height = 0;

    nestedElement.classList.toggle("menu__item--active");
    if (subMenu.clientHeight === 0) {
      height = subMenu.scrollHeight;
    }

    subMenu.style.height = `${height}px`;
  });
};

/**
 * Clean click active class when screen is bigger
 */
const deleteStyleHeight = () => {
  if (nestedElement.lastElementChild.getAttribute("style")) {
    nestedElement.lastElementChild.removeAttribute("style");
    nestedElement.classList.remove("menu__item--active");
  }
};

/**
 * Menu Event Listener - Hide / Show Menu
 */

menu.addEventListener("click", () => {
  const figure = menuImage.getAttribute("src");
  list.classList.toggle("menu__links--show");
  document.body.classList.toggle("stop-scrolling");
  menuImage.src = figure.includes("menu") ? closeImg : menuImg;
});

if (window.innerWidth < 665) {
  addClick();
}

window.addEventListener("resize", () => {
  if (window.innerWidth > 665) {
    deleteStyleHeight();
    //Set menu icon
    menuImage.src = menuImg;

    if (list.classList.contains("menu__links--show"))
      list.classList.remove("menu__links--show");

    if (document.body.classList.contains("stop-scrolling"))
      document.body.classList.remove("stop-scrolling");
  } else {
    addClick();
  }

  if (window.innerWidth <= 665) {
    addClick();
  }
});

/**
 * Page Navigation
 */
list.addEventListener("click", function (e) {
  e.preventDefault();

  if (e.target.classList.contains("img__data")) {
    const url = event.target.parentElement.getAttribute("href");
    //Guard clause
    if (!url) return;
    window.open(url, "_blank");
  }

  if (e.target.classList.contains("menu__link")) {
    const id = e.target.getAttribute("href");
    //Guard clause
    if (id !== "#" && id.includes("#")) {
      const figure = menuImage.getAttribute("src");
      document.querySelector(id).scrollIntoView({ behavior: "auto" });

      if (window.innerWidth <= 665) {
        document.body.classList.toggle("stop-scrolling");
        list.classList.toggle("menu__links--show");
        menuImage.src = figure.includes("menu") ? closeImg : menuImg;
      }
    }
  }
});

/**
 * Team & Integrants
 */

const [teamBtn, _, integrantsBtn] = Array.from(teamBtnContainer.children);

teamBtn.addEventListener("click", function (e) {
  e.preventDefault();

  team.classList.remove("hidden");
  footerTeam.classList.remove("hidden");
  integrantSwiper.classList.add("hidden");
  footerIntegrants.classList.add("hidden");
});

integrantsBtn.addEventListener("click", function (e) {
  e.preventDefault();

  team.classList.add("hidden");
  footerTeam.classList.add("hidden");
  integrantSwiper.classList.remove("hidden");
  footerIntegrants.classList.remove("hidden");
});

/**
 * Projects & Services
 */

const [projectsBtn, servicesBtn] = Array.from(projectBtnContainer.children);

projectsBtn.addEventListener("click", function (e) {
  e.preventDefault();
  swiperProjects.classList.remove("hidden");
  masonry.classList.add("hidden");
  arrows.forEach((arrow) => arrow.classList.remove("hidden"));
  upperArrows.forEach((arrow) => arrow.classList.remove("hidden"));
});

servicesBtn.addEventListener("click", function (e) {
  e.preventDefault();
  swiperProjects.classList.add("hidden");
  masonry.classList.remove("hidden");
  arrows.forEach((arrow) => arrow.classList.add("hidden"));
  upperArrows.forEach((arrow) => arrow.classList.add("hidden"));
});

/**
 * Contact Step Form
 */

let currentTab = 0;

const restoreOverlay = () => {
  document.body.classList.remove("stop-scrolling");
  loading.classList.add("hidden");
};

const resetErrorMessage = () => {
  errorMessage.textContent = "";
  errorMessage.style.display = "none";
};

inputs.forEach((input) =>
  input.addEventListener("input", function () {
    input.className = "contact__input";
    resetErrorMessage();
  })
);

const validateForm = () => {
  const currentInput = inputs[currentTab];

  let valid = true;

  if (!currentInput.value) {
    currentInput.classList.add("invalid");
    valid = false;
    errorMessage.textContent = errorMessages["required"];
    errorMessage.style.display = "block";
  }

  if (
    currentInput.getAttribute("name") === "email" &&
    !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(currentInput.value)
  ) {
    currentInput.classList.add("invalid");
    valid = false;
    errorMessage.textContent = errorMessages["email"];
    errorMessage.style.display = "block";
  }

  if (
    currentInput.getAttribute("name") === "phone" &&
    !/^\+?([0-9]{10})?([0-9]{12})?$/.test(currentInput.value)
  ) {
    currentInput.classList.add("invalid");
    valid = false;
    errorMessage.textContent = errorMessages["phone"];
    errorMessage.style.display = "block";
  }

  if (valid) {
    steps[currentTab].textContent = "\u2713";
  }

  return valid;
};

const nextTab = (n) => {
  //Exit function if field is invalid
  if (n === 1 && !validateForm()) return;

  //Hide current tab
  inputs[currentTab].style.display = "none";
  //Increase current tab
  currentTab = currentTab + n;

  //Check if we have reached the end of the form
  if (currentTab < inputs.length) showTab(currentTab);
};

const fixStepIndicator = (n) => {
  //Remove active class of all steps and descriptions
  steps.forEach((step) => step.classList.remove("tab--active"));
  stepsDes.forEach((step) => step.classList.remove("tab-text--active"));

  //Add active class to the current step
  steps[n].classList.add("tab--active");
  stepsDes[n].classList.add("tab-text--active");
};

const showTab = (n) => {
  inputs[n].style.display = "block";

  //Previous button statement
  previousBtn.style.display = n === 0 ? "none" : "inline";

  //Next or submit button
  nextBtn.style.display = n === inputs.length - 1 ? "none" : "inline";
  submitBtn.style.display = n === inputs.length - 1 ? "inline" : "none";

  //Assign steps colors
  fixStepIndicator(n);
};

const resetForm = () => {
  currentTab = 0;
  steps.forEach((step) => step.classList.remove("tab--finish"));
  stepsDes.forEach((stepDes) => stepDes.classList.remove("tab-text--finish"));
  steps.forEach((step) => step.classList.remove("tab--active"));
  steps.forEach((step, i) => (step.textContent = i + 1));
  stepsDes.forEach((step) => step.classList.remove("tab-text--active"));
  inputs.forEach((input) => {
    input.value = "";
    input.style.display = "none";
  });

  inputs[0].style.display = "block";
  steps[0].classList.add("tab--active");
  stepsDes[0].classList.add("tab-text--active");
  submitBtn.style.display = "none";
  previousBtn.style.display = "none";
  nextBtn.style.display = "block";
};

const sendMail = (data) => {
  emailjs
    .send("service_p7zymng", "template_ov5ye5r", {
      company: "P&P Agencia Creativa",
      name: data.name,
      email: data.email,
      phone: data.phone,
      message: data.message,
    })
    .then((res) => {
      restoreOverlay();
      Swal.fire({
        icon: "success",
        allowOutsideClick: false,
        title: "¡Mensaje enviado con éxito!",
        text: "Nuestro equipo se pondrá en contacto contigo a la brevedad",
        confirmButtonText: "Entendido",
        confirmButtonColor: "#2e2a62",
      }).then((result) => {
        if (result.isConfirmed) resetForm();
      });
    })
    .catch((e) => {
      console.error(e.message);
      restoreOverlay();
      Swal.fire({
        icon: "error",
        allowOutsideClick: false,
        title: "¡Ha ocurrido un error!",
        text: "Lo sentimos, estamos presentando dificultades técnicas, por favor intente más tarde",
        confirmButtonText: "Entendido",
        confirmButtonColor: "#2e2a62",
      }).then((result) => {
        if (result.isConfirmed) resetForm();
      });
    });
};

form.addEventListener("keydown", function (e) {
  var key = e.charCode || e.keyCode || 0;
  if (key == 13) {
    e.preventDefault();
    console.log("No enter");
  }
});

form.addEventListener("submit", function (e) {
  e.preventDefault();
  const dataArray = [...new FormData(this)];
  const data = Object.fromEntries(dataArray);
  sendMail(data);
  loading.classList.remove("hidden");
  document.body.classList.add("stop-scrolling");
});

emailjs.init("DHiVpQV6e7poFrl9o");

previousBtn.addEventListener("click", function (e) {
  e.preventDefault();
  nextTab(-1);
  resetErrorMessage();
  inputs.forEach((input) => input.classList.remove("invalid"));
});

nextBtn.addEventListener("click", function (e) {
  e.preventDefault();
  nextTab(1);
});

showTab(currentTab);

/******************************************************** */
/**Team Slider */
const teamSwiper = new Swiper(".swiper-team", {
  modules: [Navigation, Pagination],
  slidesPerView: 1,
  spaceBetween: 10,
  pagination: {
    el: ".swiper-pagination",
    clickable: true,
  },
  breakpoints: {
    768: {
      slidesPerView: 2,
      spaceBetween: 0,
      slidesPerGroup: 2,
    },
  },
});

/** Projects Slider */
const projectsSwiper = new Swiper(".swiper-projects", {
  modules: [Navigation, Pagination],
  slidesPerView: 1,
  spaceBetween: 10,
  navigation: {
    nextEl: ".right-b",
    prevEl: ".left-b",
  },
  breakpoints: {
    768: {
      slidesPerView: 2,
      spaceBetween: 10,
    },
    1024: {
      slidesPerView: 3,
      spaceBetween: 10,
    },
    1600: {
      slidesPerView: 3,
      spaceBetween: 20,
    },
  },
});

/** Customers Slider */
const customersSwiper = new Swiper(".swiper-customers", {
  modules: [Navigation, Pagination],
  slidesPerView: 1,
  spaceBetween: 10,
  pagination: {
    el: ".swiper-pagination",
    clickable: true,
  },
  breakpoints: {
    500: {
      slidesPerView: 2,
      spaceBetween: 10,
      slidesPerGroup: 2,
    },
    768: {
      slidesPerView: 3,
      spaceBetween: 10,
      slidesPerGroup: 3,
    },
    1050: {
      slidesPerView: 4,
      spaceBetween: 10,
      slidesPerGroup: 4,
    },
    1300: {
      slidesPerView: 5,
      spaceBetween: 10,
      slidesPerGroup: 5,
    },
  },
});
